import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { PageProps, graphql } from 'gatsby';
import { compact, get } from 'lodash';
import HeroPost from '../../components/HeroPost';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import Breadcrumb from '../../components/Breadcrumb';
import Divider from '../../components/Divider';
import TileCard from '../../components/TileCard';
import { ModalRawHTML } from '../../components/RawHTML/ModalRawHTML';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import { Scalars, SingleBlogPostQuery } from '../../../graphql.schema';
import SEO from '../../components/SEO/SEO';
import { GlobalPageContext } from '../../types';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './blog-post.module.scss';

type PageContextType = GlobalPageContext & {
  documentId: Scalars['ID'];
};


const BlogPage: React.FC<PageProps<SingleBlogPostQuery, PageContextType>> = ({ data, pageContext }) => {

  const { websiteLocale: websiteLocaleV3, locale } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const entry = data.strapi.blogPost
  const intl = useIntl();
  const { blogPost } = data.strapi;
  if (!entry || !websiteLocale)
    return (
      <h1>
        <FormattedMessage id="notFoundTextHeading" defaultMessage="Page cannot be found." />
      </h1>
    );
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={entry.seo?.metaTitle ?? entry.pageSettings?.title ?? entry.title ?? ''}
        description={entry.seo?.metaDescription ?? entry.pageSettings?.metaDescription ?? entry.excerpt}
        image={entry.seo?.metaImage?.url ?? entry.image?.url}
        avoidIndexing={entry.pageSettings?.avoidIndexing ?? false}
        canonicalURL={entry.seo?.canonicalURL}
        keywords={entry.seo?.keywords}
        metaSocial={entry.seo?.metaSocial}
        structuredData={
          entry.seo?.structuredData ?? {
            '@context': 'https://schema.org',
            '@type': 'BlogPosting',
            headline: entry.title,
            image: [entry.image?.url],
            datePublished: entry.publishedAt,
            dateModified: entry.updatedAt,
          }
        }
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className="container">
        <Breadcrumb pageContext={pageContext} lastCrumb={entry.title} locale={locale}></Breadcrumb>
        <div className="main-content">
          <HeroPost
            variant="blog"
            title={entry.title}
            categories={compact(entry?.categories)}
            date={entry.originalDate ?? entry.publishedAt}
            imageSrc={entry.image?.url}
          />
          <RichTextField className={styles.container} content={entry.body} />
          <ModalRawHTML html={entry.htmlCode ?? ''} />
        </div>
      </div>
      <div
        className={classnames(
          'container page-components',
          styles['pageComponents'],
          styles['courseButton'],
          websiteLocale?.hasRightToLeftLanguage ? styles['courseColumnListRtl'] : '',
        )}
      >
        {blogPost?.components?.map((component, idx) =>
          component ? (
            <ComponentRenderer
              component={component}
              key={`blog-post-component-${component.__typename}-${idx}`}
              hasRTL={websiteLocale?.hasRightToLeftLanguage}
            />
          ) : null,
        )}
      </div>

      {data.strapi.relatedPosts?.length ? (
        <div className="container">
          <Divider paddingTop="100px" marginBottom="20px">
            <FormattedMessage id="blogDividerText" defaultMessage="More blog posts" />
          </Divider>
          <div className={styles.gridWrapper}>
            {compact(data.strapi.relatedPosts).map(
              ({ documentId, title, slug, image, categories, excerpt, originalDate, publishedAt }) => (
                <TileCard
                  linkText={intl.formatMessage({ id: 'blogCardText', defaultMessage: 'Read article' })}
                  slugBase={pageContext.pathPrefixes.blog}
                  key={`blog-${documentId}`}
                  variant="blog"
                  title={title ?? 'N/A'}
                  slug={slug ?? ''}
                  image={image?.url}
                  categories={compact(categories)}
                  short={excerpt ?? ''}
                  date={originalDate ?? publishedAt}
                />
              ),
            )}
          </div>
        </div>
      ) : null}
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>

  );
};

export const query = graphql`
query SingleBlogPost($documentId: ID!, $websiteLocaleId: ID!, $relatedIds: [ID!], $locale: Strapi_I18NLocaleCode) {
  strapi {
    relatedPosts: blogPosts(
      filters: {documentId: {in: $relatedIds, ne: $documentId}, website_locale: {documentId: {eq: $websiteLocaleId}}}
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          image {
            data {
              documentId
              attributes {
                ...StrapiImage
              }
            }
          }
          categories {
            data {
              documentId
              attributes {
                name
              }
            }
          }
          excerpt
          publishedAt
          originalDate: original_date
        }
      }
    }
    blogPost(documentId: $documentId,locale: $locale) {
      data {
        documentId
        attributes {
          title
          slug
          publishedAt
          updatedAt
          originalDate: original_date
          excerpt
          body
          htmlCode: html_code
          pageSettings: page_settings {
            ...PageSettings
          }
          categories {
            data {
              documentId
              attributes {
                name
              }
            }
          }
          image {
            data {
              documentId
              attributes {
                ...StrapiImage
              }
            }
          }
          components {
            ...PageComponents
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(BlogPage));